
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






























































































































































































































/* stylelint-disable declaration-no-important, max-nesting-depth, no-descending-specificity */
.advantage-choice,
[class*='advantage-choice--'] {
  overflow: hidden;
  width: 100vw;
  max-width: 86rem;
  min-height: 100%;
  padding: 5rem 2rem;
  background: $white;

  ::v-deep {
    .promo-card {
      overflow: hidden;
      box-shadow: 0 20px 40px rgba($black, 0.2);
    }

    .promo-card__nav {
      display: none;
    }
  }

  @include mq(m) {
    padding: 5rem;
  }
}

.advantage-choice__inner {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;

  .is-ready & {
    visibility: visible;
    opacity: 1;
  }
}

.advantage-choice__header {
  text-align: center;
}

.advantage-choice__label {
  display: inline-block;
  margin: 0 auto 0.5rem;

  @include mq(m) {
    margin: 0 auto 1.5rem;
    padding: 0.9rem 1.5rem;
  }
}

.advantage-choice__title {
  margin-bottom: 3rem;
  font-size: 1.8rem;
  text-align: center;

  ::v-deep strong {
    display: block;
    font-size: 3.6rem;
  }

  @include mq(m) {
    margin-bottom: 6.4rem;
    font-size: 4.8rem;
    text-align: center;

    ::v-deep strong {
      display: block;
      font-size: 6.2rem;
    }
  }
}

.advantage-choice__products {
  margin: 0 0 2rem;

  @include mq(m) {
    margin: 0 -3rem 3rem;

    .promo-card {
      margin-bottom: 0;
    }
  }
}

.advantage-choice__products__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 7.5rem;
}

.advantage-choice__products__list-highlight {
  max-width: 34rem;

  @include mq(m) {
    flex-basis: calc(50% - 6rem);
    max-width: none;
    margin-bottom: 3rem !important;
  }
}

.advantage-choice__products__slider {
  position: relative;
  width: 100%;
  min-height: 45rem;

  ::v-deep .promo-card {
    max-width: 30rem;
    height: 35rem;

    & + .promo-card {
      margin-left: $spacing;
    }

    .promo-card__picture-outer {
      height: 20rem;
    }

    @include mq($until: m) {
      width: 30rem;
    }
  }

  .generic-slider {
    position: static;
  }

  ::v-deep .flickity-button {
    position: absolute;
    bottom: 0;

    &.next {
      left: 5.5rem;
    }
  }
}
